var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitUpdate.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-card",
            { staticClass: "mb-1", attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c("b-card-title", { staticClass: "mb-0" }, [
                    _vm._v(" Dados "),
                  ]),
                ],
                1
              ),
              _c("b-card-body", { staticClass: "pl-2 pr-2" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-md-5" }, [
                    _c("div", { staticClass: "form-group mb-md-0" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Nome "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.item.company_name,
                            expression: "item.company_name",
                          },
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.$v.item.company_name.$error,
                        },
                        attrs: { type: "text" },
                        domProps: { value: _vm.item.company_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.item,
                              "company_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "div",
                      { staticClass: "form-group mb-md-0" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _c("i", {
                            staticClass: "text-danger bi bi-record-circle",
                          }),
                          _vm._v(" Situação "),
                        ]),
                        _c(
                          "v-select",
                          {
                            class: { "is-invalid": _vm.$v.item.active.$error },
                            attrs: {
                              label: "title",
                              "item-text": "title",
                              "item-value": "code",
                              searchable: false,
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.item.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "active", $$v)
                              },
                              expression: "item.active",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v(" Nenhum registro encontrado ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-card",
            {
              staticClass: "card-header-custom-actions",
              attrs: { "header-tag": "header" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-11" }, [
                          _c("h4", { staticClass: "mb-0" }, [
                            _c("i", {
                              staticClass: "text-danger bi bi-record-circle",
                            }),
                            _vm._v(" Unidades de negócio "),
                          ]),
                          _c("small", { staticClass: "mb-0" }, [
                            _vm._v(
                              "Selecione as unidades de negócio que farão parte desta Unidade estratégica."
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-1" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                staticClass: "btn-block",
                                attrs: { variant: "primary" },
                                on: { click: _vm.repeateAgainBusinessUnit },
                              },
                              [_c("i", { staticClass: "bi bi-plus mr-25" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.item.business_units.length
                ? _c(
                    "b-card-text",
                    { staticClass: "mt-0" },
                    _vm._l(_vm.item.business_units, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: "business-units-key-" + index,
                          staticClass: "form-row mb-1 align-items-center",
                        },
                        [
                          _c("div", { staticClass: "col-md-11" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c(
                                  "v-select",
                                  {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.item.business_units.$error,
                                    },
                                    attrs: {
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Unidade de negócio",
                                      searchable: false,
                                      options: _vm.optionsBusinessUnits,
                                    },
                                    model: {
                                      value: item.item,
                                      callback: function ($$v) {
                                        _vm.$set(item, "item", $$v)
                                      },
                                      expression: "item.item",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-1" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(234, 84, 85, 0.15)",
                                        expression: "'rgba(234, 84, 85, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    attrs: {
                                      variant: "outline-danger",
                                      block: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeBusinessUnit(index)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "bi bi-trash3" })]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _c("b-card-text", { staticClass: "mt-0" }, [
                    _vm._v(
                      " Nenhuma unidade de negócio cadastrada até o momento. "
                    ),
                  ]),
            ],
            1
          ),
          _c("div", { staticClass: "form-row justify-content-end" }, [
            _c(
              "div",
              { staticClass: "col-md-2" },
              [
                _c("ButtonsActionsFooter", {
                  attrs: {
                    variant: "success",
                    submited: _vm.submited,
                    text: "Salvar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }